<template>
  <div class="d-flex justify-content-end align-items-center">
    <!--update | delete |block student -->
    <b-button variant="primary" class="mx-1" @click="submit">تعديل</b-button>
    <b-button @click="setIsBlock" variant="outline-danger" class="mr-1"
      >{{ studentDto.isBlocked ? "الغاء الحظر" : "حظر" }}
    </b-button>
    <b-button variant="danger" @click="deleteStu(studentDto.id)">حذف</b-button>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  computed: {
    ...mapState({
      studentDto: (state) => state.students.studentDto,
    }),
  },
  methods: {
    ...mapActions(["updateStudent", "blockStudent", "unblockStudent"]),
    deleteStu(id) {
      this.$store.dispatch("deleteStudent", [id]);
    },
    submit() {
      this.updateStudent(this.studentDto);
    },
    setIsBlock() {
      if (!this.studentDto.isBlocked) {
        this.blockStudent(this.studentDto.id);
      } else this.unblockStudent(this.studentDto.id);
    },
  },
};
</script>
